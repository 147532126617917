import {
  title,
  paragraphs,
  quote,
  secondaryQuote,
  secondaryAuthor,
  author,
} from "../content/bf.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import Quote from "../components/Quote";
import { Fragment } from "react";
import kabr from "../img/Karin.png";

const Bf = () => {
  const halfWayIndex = Math.ceil(paragraphs.length / 3);

  const firstPart = paragraphs.slice(0, halfWayIndex);
  const secondPart = paragraphs.slice(halfWayIndex, halfWayIndex * 2);
  const thirdPart = paragraphs.slice(halfWayIndex * 2);
  console.log(thirdPart);

  return (
    <Fragment>
      <Row>
        <Col sm="12" lg="6">
          <Container>
            <TextContent title={title} content={firstPart} />
          </Container>
        </Col>
        <Col sm="12" lg="6">
          <Media bg={kabr} />
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="6">
          <Media />
          <Quote quote={quote} author={author} customClass="left" />
        </Col>
        <Col sm="12" lg="6">
          <Container>
            <TextContent content={secondPart} />
          </Container>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="6">
          <Container>
            <TextContent content={thirdPart} name="Karin Brönnimann" />
          </Container>
        </Col>
        <Col sm="12" lg="6">
          <Media />
          <Quote
            quote={secondaryQuote}
            author={secondaryAuthor}
            customClass="right"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Bf;

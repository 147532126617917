const { Row, Col, Container } = require("react-bootstrap");

const Imprint = () => {
  return (
    <div className="content-wrapper content-imprint">
      <Row>
        <Col>
          <Container>
            <Row>
              <h1>Bildverzeichnis</h1>
              <Col md={2}>Abb. 1</Col>
              <Col>Vorwort</Col>
              <Col md={6}>
                <p>
                  <strong>Manuela Dalle Carbonare</strong>
                </p>
                <p>Stiftungsratspräsidentin</p>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Abb. 2</Col>
              <Col>Geschäftsleitung</Col>
              <Col md={6}>
                <p>
                  <strong>Monika Lorenzen</strong>
                </p>
                <p>Geschäftsleitung</p>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Abb. 3</Col>
              <Col>Ausbildungsbereich</Col>
              <Col md={6}>
                <p>
                  <strong>Stefanie Stalder</strong>
                </p>
                <p>Leitung Ausbildungsbereich</p>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Abb. 4</Col>
              <Col>Berufsfindung</Col>
              <Col md={6}>
                <p>
                  <strong>Karin Brönnimann</strong>
                </p>
                <p>Leitung Berufsfindung</p>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Abb. 5</Col>
              <Col>Schule</Col>
              <Col md={6}>
                <p>
                  <strong>Roman Rohner</strong>
                </p>
                <p>Schulleitung</p>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Abb. 6</Col>
              <Col>Wohnen / Wohncoaching</Col>
              <Col md={6}>
                <p>
                  <strong>Claire Droz</strong>
                </p>
                <p>Leitung Coaching</p>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Abb. 7</Col>
              <Col>Backoffice</Col>
              <Col md={6}>
                <p>
                  <strong>Nicole Goetschi</strong>
                </p>
                <p>Stellvertretende Geschäftsleitung / Leitung Backoffice</p>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container>
            <Row>
              <Col>
                <h1>Impressum</h1>
                <p className="intro">
                  Der Jahresbericht 2020 erschien in aussergewöhnlicher Form. Er
                  wurde gänzlich durch unsere Lernenden umgesetzt. Dabei richtet
                  sich das Erscheinungsbild an einer möglichst herausfordernden,
                  aber lehrreichen Aufgabenstellung. Mittels solcher Projekte
                  können wir ein realitätsnahes Arbeitsumfeld schaffen und
                  unseren Klienten arbeitsmarktgerecht durch Projekte begleiten.
                  Sie lernen so die benötigten Teilschritte bei der
                  Projektplanung, -realisierung und -auswertung kennen und
                  erarbeiten diese selbstständig.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Layout Druckversion</p>
              </Col>
              <Col>
                <p>Lernender Polygraf EFZ</p>
                <p>2. Lehrjahr; Stiftung autismuslink</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Bilder</p>
              </Col>
              <Col>
                <p>Lernender Mediamatik EFZ</p>
                <p>1. Lehrjahr; Stiftung autismuslink</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Schrift</p>
              </Col>
              <Col>
                <p>Silka Regular, Regular Italic, Bold</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Farben</p>
              </Col>
              <Col>
                <p>Schwarz und Pantone 353 U (Offset)</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Papier</p>
              </Col>
              <Col>
                <p>Lessebo Rough Ivory 120g/m2</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Druck</p>
              </Col>
              <Col>
                <p>Druckerei Hofer Bümpliz AG</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Auflage</p>
              </Col>
              <Col>
                <p>300 Ex.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Website</p>
              </Col>
              <Col>
                <p>Stiftung autismuslink</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Website Technologie</p>
              </Col>
              <Col>
                <p>ReactJS / react-bootstrap / scss</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Imprint;

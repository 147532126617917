import { Nav } from "react-bootstrap";

const NavPartial = (props) => {
  return (
    <Nav className={props.customClass}>
      <Nav.Link href="/">Übersicht</Nav.Link>
      <Nav.Link href="/vorwort">Vorwort</Nav.Link>
      <Nav.Link href="/stiftungsrat">Wort des Stiftungsrat</Nav.Link>
      <Nav.Link href="/geschaeftsleitung">Geschäftsleitung</Nav.Link>
      <Nav.Link href="/ausbildung">Ausbildungsbereich</Nav.Link>
      <Nav.Link href="/berufsfindung">Berufsfindung</Nav.Link>
      <Nav.Link href="/schule">Schule</Nav.Link>
      <Nav.Link href="/wohnen-wohncoaching">Wohnen/Wohncoaching</Nav.Link>
      <Nav.Link href="/coaching">Coaching</Nav.Link>
      <Nav.Link href="/weiterbildung">Weiterbildung</Nav.Link>
      <Nav.Link href="/backoffice">Backoffice</Nav.Link>
      <Nav.Link href="/zahlen">Jahresrechnung</Nav.Link>
      <Nav.Link href="/impressum">Impressum & Bildverzeichnis</Nav.Link>
    </Nav>
  );
};

export default NavPartial;

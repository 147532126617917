import shortid from "shortid";
import Footer from "./Footer";

const TextContent = (props) => {
  const content = props.content.map((element) => (
    <p key={shortid.generate()}>{element}</p>
  ));

  return (
    <div className="content-wrapper">
      <h1>{props.title}</h1>
      {props.subtitle && <h2>{props.subtitle}</h2>}
      <section className="content">{content}</section>
      <Footer name={props.name} />
    </div>
  );
};

export default TextContent;

import { title, subtitle, paragraphs } from "../content/preface.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import manuela from "../img/Manuela.png";

const Preface = () => {
  return (
    <Row>
      <Col sm="12" lg="6">
        <Container>
          <TextContent
            key={title}
            title={title}
            subtitle={subtitle}
            content={paragraphs}
            name="Manuela Dalle Carbonare"
          />
        </Container>
      </Col>
      <Col sm="12" lg="6">
        <Media bg={manuela} />
      </Col>
    </Row>
  );
};

export default Preface;

import { title, paragraphs, quote, author } from "../content/school.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import Quote from "../components/Quote";
import { Fragment } from "react";
import roro from "../img/Roman.png";

const School = () => {
  const halfWayIndex = Math.ceil(paragraphs.length / 3);

  const firstPart = paragraphs.slice(0, halfWayIndex);
  const secondPart = paragraphs.slice(halfWayIndex);

  return (
    <Fragment>
      <Row>
        <Col sm="12" lg="6">
          <Container>
            <TextContent title={title} content={firstPart} />
          </Container>
        </Col>
        <Col sm="12" lg="6">
          <Media bg={roro} />
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="6">
          <Media />
          <Quote quote={quote} author={author} customClass="left" />
        </Col>
        <Col sm="12" lg="6">
          <Container>
            <TextContent content={secondPart} name="Roman Rohner" />
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default School;

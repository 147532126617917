import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import NavPartial from "../components/NavPartial";
import Media from "../components/Media";
import titleScreen from "../img/JB_Titelseite.png";

function Home() {
  return (
    <Layout>
      <Row>
        <Col sm="12" lg="6">
          <Media bg={titleScreen} />
        </Col>
        <Col sm="12" lg="6">
          <div className="content-wrapper wrapper-home">
            <h1 className="large">Inhalt</h1>
            <NavPartial customClass="justify-content-center home-nav" />
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Home;

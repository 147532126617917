import { title, paragraphs } from "../content/residence-coaching.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import { Fragment } from "react";
import cldr from "../img/Claire.png";

const ResidenceCoaching = () => {
  const halfWayIndex = Math.ceil(paragraphs.length / 3);

  const firstPart = paragraphs.slice(0, halfWayIndex);
  const secondPart = paragraphs.slice(halfWayIndex);

  return (
    <Fragment>
      <Row>
        <Col sm="12" lg="6">
          <Container>
            <TextContent title={title} content={firstPart} />
          </Container>
        </Col>
        <Col sm="12" lg="6">
          <Media bg={cldr} />
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="6">
          <Media />
        </Col>
        <Col sm="12" lg="6">
          <Container>
            <TextContent content={secondPart} name="Claire Droz" />
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ResidenceCoaching;

const Media = (props) => {
  var mediaStyle = {
    backgroundImage: `url(${props.bg})`,
  };
  console.log(mediaStyle.backgroundImage);
  return (
    <div
      className={`media${
        mediaStyle.backgroundImage == "url(undefined)" ? " blank-content" : ""
      }`}
      style={mediaStyle}
    ></div>
  );
};

export default Media;
  
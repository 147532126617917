const Quote = (props) => {
  return (
    <div className={`quote ${props.customClass}`}>
      <p>{props.quote}</p>
      <small>{props.author}</small>
    </div>
  );
};

export default Quote;

import { title, paragraphs, quote, author } from "../content/training.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import nigo from "../img/Nicole.png";
import Quote from "../components/Quote";

const Training = () => {
  const halfWayIndex = Math.ceil(paragraphs.length / 2);

  const firstPart = paragraphs.slice(0, halfWayIndex);
  const secondPart = paragraphs.slice(halfWayIndex);

  return (
    <Row>
      <Col sm="12" lg="6">
        <Container>
          <TextContent key={title} title={title} content={firstPart} />
          <Quote quote={quote} author={author} customClass="inline" />
          <TextContent
            key={title + 1}
            content={secondPart}
            name="Nicole Goetschi"
          />
        </Container>
      </Col>
      <Col sm="12" lg="6">
        <Media bg={nigo} />
      </Col>
    </Row>
  );
};

export default Training;

import { Navbar, Container, Offcanvas } from "react-bootstrap";
import { ReactComponent as Icon } from "../img/menu.svg";
import NavPartial from "./NavPartial";

const Navigation = () => {
  return (
    <Navbar expand={false} fixed="top">
      <Container fluid>
        <Navbar.Toggle aria-controls="offcanvasNavbar">
          <Icon /> <span>Jahresbericht 2020</span>
        </Navbar.Toggle>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              {
                //title
              }
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <NavPartial customClass="justify-content-end flex-grow-2 pe-3" />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};
export default Navigation;

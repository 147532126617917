import {
  title,
  subtitle,
  paragraphs,
  quote,
  author,
} from "../content/foundation.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import Quote from "../components/Quote";

const Foundation = () => {
  return (
    <Row>
      <Col sm="12" lg="6">
        <Media />
        <Quote quote={quote} author={author} customClass="left" />
      </Col>
      <Col sm="12" lg="6">
        <Container>
          <TextContent
            title={title}
            subtitle={subtitle}
            content={paragraphs}
            name="Manuela Dalle Carbonare"
          />
        </Container>
      </Col>
    </Row>
  );
};

export default Foundation;

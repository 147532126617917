import { Container, Table } from "react-bootstrap";
import shortid from "shortid";
import { Bilanz, Erfolgsrechnung } from "../content/finances.json";

const Finance = () => {
  const finances = Erfolgsrechnung.map((element) => {
    return (
      <tr key={shortid.generate()}>
        <td colSpan={2}>{element.position}</td>
        <td className="highlight align-right">{element.finances2020}</td>
        <td className="align-right">{element.finances2019}</td>
      </tr>
    );
  });

  const results = Bilanz.map((element) => {
    return (
      <tr key={shortid.generate()}>
        <td colSpan={2}>{element.position}</td>
        <td className="highlight align-right">{element.results2020}</td>
        <td className="align-right">{element.results2019}</td>
      </tr>
    );
  });

  return (
    <div className="content-wrapper content-wrapper-finance">
      <Container>
        <h1>Jahresrechnung 2020</h1>
        <article className="finance">
          <Table responsive className="finances">
            <thead>
              <tr className="highlight">
                <th colSpan={2}>Bilanz</th>
                <th className="align-right">31.12.2020</th>
                <th className="align-right">31.12.2019</th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <th className="align-right">CHF</th>
                <th className="align-right">CHF</th>
              </tr>
            </thead>
            <tbody>{results}</tbody>
          </Table>
        </article>
        <article className="finance">
          <Table responsive className="results">
            <thead>
              <tr className="highlight">
                <th colSpan={2}>Erfolgsrechnung</th>
                <th className="align-right">31.12.2020</th>
                <th className="align-right">31.12.2019</th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <th className="align-right">CHF</th>
                <th className="align-right">CHF</th>
              </tr>
            </thead>
            <tbody>{finances}</tbody>
          </Table>
        </article>
        <article>
          <small>
            <p>
              Auszug aus dem Bericht der Revisionsstelle zur Eingeschränkten
              Revision: Als Revisionsstelle haben wir die Jahresrechnung
              (Bilanz, Erfolgsrechnung und Anhang) der Stiftung autismuslink -
              Kompetenzzentrum für Menschen mit Autismus, für das am 31.
              Dezember 2020 abgeschlossene Geschäftsjahr geprüft. Bei unserer
              Revision sind wir nicht auf Sachverhalte gestossen, aus denen wir
              schliessen müssten, dass die Jahresrechnung nicht Gesetz und
              Statuten entspricht.
            </p>
            <p>Kämpf & Stauffer Treuhand AG | Interlaken, 21. Mai 2021</p>
          </small>
        </article>
      </Container>
    </div>
  );
};

export default Finance;

import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/App.scss";

import { Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import Layout from "./components/Layout";

import Home from "./pages/Home";
import Preface from "./pages/Preface";
import Foundation from "./pages/Foundation";
import Management from "./pages/Management";
import Education from "./pages/Education";
import Bf from "./pages/Bf";
import School from "./pages/School";
import ResidenceCoaching from "./pages/ResidenceCoaching";
import Coaching from "./pages/Coaching";
import Training from "./pages/Training";
import Backoffice from "./pages/Backoffice";
import Imprint from "./pages/Imprint";
import Finance from "./pages/Finances";

function App() {
  return (
    <main className="App">
      <Navigation />
      <Layout>
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/vorwort" element={<Preface />} />
          <Route exact path="/stiftungsrat" element={<Foundation />} />
          <Route exact path="/geschaeftsleitung" element={<Management />} />
          <Route exact path="/ausbildung" element={<Education />} />
          <Route exact path="/berufsfindung" element={<Bf />} />
          <Route exact path="/schule" element={<School />} />
          <Route exact path="/coaching" element={<Coaching />} />
          <Route exact path="/weiterbildung" element={<Training />} />
          <Route exact path="/backoffice" element={<Backoffice />} />
          <Route exact path="/impressum" element={<Imprint />} />
          <Route exact path="/zahlen" element={<Finance />} />
          <Route
            exact
            path="/wohnen-wohncoaching"
            element={<ResidenceCoaching />}
          />
        </Routes>
      </Layout>
    </main>
  );
}

export default App;
import { title, paragraphs, quote, author } from "../content/coaching.json";
import TextContent from "../components/TextContent";
import Media from "../components/Media";
import { Col, Row, Container } from "react-bootstrap";
import Quote from "../components/Quote";
import { Fragment } from "react";

const Coaching = () => {
  return (
    <Fragment>
      <Row>
        <Col sm="12" lg="6">
          <Media />
          <Quote quote={quote} author={author} customClass="left" />
        </Col>
        <Col sm="12" lg="6">
          <Container>
            <TextContent
              title={title}
              content={paragraphs}
              name="Monika Lorenzen"
            />
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Coaching;
